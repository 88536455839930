// theme.js
import { createTheme } from '@mui/material/styles'; // MUI v4
// OR for MUI v5
// import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffc400',
        },
    },
    typography: {
        fontFamily: 'Mukta',
        fontSize: '16px',
    },
    shape: {
        borderRadius: 12,
    },
});
