import http from "../../config/http-common";

class UserService {
  updateUser = (user) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/Update/" + user.firstName + "/" + user.lastName)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateEmailAddress = (emailAddress, emailAddressID, isRecoveryEmail) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/AddUpdateEmailAddress/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            isRecoveryEmail
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyEmailOtp = (emailAddress, emailAddressID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdatePhoneNumber = (
    phoneNumber,
    phoneNumberID,
    isRecoveryPhoneNumber
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "User/AddUpdatePhoneNumber/" +
            phoneNumber +
            "/" +
            phoneNumberID +
            "/" +
            isRecoveryPhoneNumber
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyPhoneOtp = (phone, phoneNumberID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyPhoneNumberVerificationCode/" +
            phone +
            "/" +
            phoneNumberID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updatePassword = (password) => {
    return new Promise((resolve, reject) => {
      http.put("User/UpdatePassword/" + password).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  resetPassword = (password, newPassword) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/ResetPassword/" + password + "/" + newPassword)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryPhone = (phone, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryPhoneNumberVerificationCode/" + phone + "/" + otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryEmail = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendEmailOTP = (emailAddress, isRecoveryEmail = false) => {
    return new Promise((resolve, reject) => {
      http
        .get("User/ReSendEmailOTP/" + emailAddress + "/" + isRecoveryEmail)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendPhoneOTP = (phone, isSecondaryPhone = false) => {
    return new Promise((resolve, reject) => {
      http.get("User/ReSendPhoneOTP/" + phone +"/"+ isSecondaryPhone).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  updateSignInPreference = (signInpreferenceTypeId) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateSignInPreference/" + signInpreferenceTypeId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryPhoneNumber = (phoneNumberID) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/SetPrimaryPhoneNumber/" + phoneNumberID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryEmailAddress = (emailAddressID) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/SetPrimaryEmailAddress/" + emailAddressID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  deleteAccount = () => {
    return new Promise((resolve, reject) => {
      http.put("User/DeleteAccount").then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  updateSecondaryEmailCount = (count) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateSecondaryEmailCount" + "/" + count)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}

const instance = new UserService();

export default instance;
