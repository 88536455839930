import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../src/app/components/private-route";
import SignIn from "./app/auth/signin/sign-in";
import ForgotPassword from "./app/auth/forgot-password/forgot-password";
import Home from "./app/main/dashboard/home";
import Setting from "./app/main/settings/setting";
import { theme } from "./app/config/style.config";
import { ThemeProvider } from "@mui/material/styles";
import TermsConditions from "./app/auth/terms/termsConditions";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/setting" element={<Setting />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
