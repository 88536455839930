import { useEffect, useState } from "react";
import authImage from "../../assets/images/tp-auth.png";
import successfullyMark from "../../assets/images/success-mark.png";
import logo from "../../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "../../../../src/@lodash";
import AuthService from "../services/authService";
import constants from "../../helpers/constants";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import PasswordChecklist from "react-password-checklist";

var emailSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

const defaultEmailValues = {
  email: "",
};

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultPasswordValues = {
  password: "",
  passwordConfirm: "",
};

function ForgotPassword() {
  const forgotPasswordSteps = constants?.forgotPasswordSteps();
  const [forgotPasswordStep, setForgotPasswordStep] = useState(
    forgotPasswordSteps.forgotPassword
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resendOTPSeconds, setResendOTPSeconds] = useState(
    constants.ResendOTPSeconds
  );
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const [newPasswordField, setNewPasswordField] = useState("");
  const [confirmPasswordField, setConfirmPasswordField] = useState("");

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  var defaultValues =
    forgotPasswordStep == forgotPasswordSteps.newPassword
      ? defaultPasswordValues
      : defaultEmailValues;
  var schema =
    forgotPasswordStep == forgotPasswordSteps.newPassword
      ? passwordSchema
      : emailSchema;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver:
      forgotPasswordStep === forgotPasswordSteps.newPassword
        ? null
        : yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue("email", "", { shouldDirty: false, shouldValidate: false });
    if (location.state?.email) {
      setForgotPasswordStep(forgotPasswordSteps.forgotPasswordOTP);
      onEmailSubmit(location.state?.email);
    }
  }, []);

  function onEmailSubmit({ email }) {
    email = email ? email : location.state?.email;
    setLoading(true);
    setEmail(email);
    AuthService.signInWithOTP(email, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            showResendOTPSeconds();
            if(!location.state?.email)
            setForgotPasswordStep(forgotPasswordSteps.forgotPasswordOTP);
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onVerifyForgotPassword() {
    setLoading(true);
    AuthService.verifyForgotPassword(email, true, otp)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            clearPasswrod();
            setForgotPasswordStep(forgotPasswordSteps.newPassword);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onForgotNewPassword() {
    setLoading(true);
    AuthService.onForgotNewPassword(email, true, newPasswordField)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setShowModal(true);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    AuthService.reSendOTP(email, true, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  const clearPasswrod = () => {
    setNewPasswordField("");
    setConfirmPasswordField("");
  };

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function showHideOTPMessage(response) {
    setShowOtpMessage(true);
    setTimeout(() => {
      setShowOtpMessage(false);
    }, 3000);
  }

  function showResendOTPSeconds() {
    var intervalId = setInterval(() => {
      setResendOTPSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return "00";
        }
        return prev < 11 ? `0${prev - 1}` : prev - 1;
      });
    }, constants.ResendOTPCount);
  }

  return (
    <div className="bg-black">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4"
                  src={logo}
                  alt="auth image"
                />
                <img className="mx-auto" src={authImage} alt="auth image" />
                <h1 className="auth-heading font-semibold mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="max-w-[600px] w-full relative">
                  <img
                    className="mx-auto hidden xl:block"
                    src={logo}
                    alt="auth image"
                  />
                  <div className="bg-white w-full rounded-xl mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                    {(forgotPasswordStep ===
                      forgotPasswordSteps.forgotPassword && (
                      <div>
                        <button
                          className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                          onClick={() => navigate("/")}
                        >
                          <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                        </button>
                        <h1 className="text-center">Forgot Your Password? </h1>
                        <p className="leading-7 text-center px-6 mt-3.5">
                          Enter your registered email address, and we'll send
                          you OTP to reset your password.
                        </p>
                        <div className="mt-[50px]">
                          <form
                            name="forgotForm"
                            noValidate
                            className="flex flex-col justify-center w-full mt-[22px]"
                            onSubmit={handleSubmit(onEmailSubmit)}
                          >
                            <div className="relative">
                              <label>Email Address</label>
                              <Controller
                                name="email"
                                control={control}
                                id="email"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="w-full p-4 rounded-xl"
                                    autoFocus={true}
                                    type="email"
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    value={null}
                                    variant="outlined"
                                    placeholder="Enter Email Address"
                                    inputProps={{ maxLength: 40 }}
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                              <div className="absolute tp-message">
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </div>
                            </div>
                            <div className="mt-12">
                              <Button
                                name="sendEmail"
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Send OTP"
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  _.isEmpty(dirtyFields) || !isValid || loading
                                }
                                type="submit"
                                size="large"
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Send OTP"}
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )) ||
                      (forgotPasswordStep ===
                        forgotPasswordSteps.forgotPasswordOTP && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                            onClick={() =>
                              setForgotPasswordStep(
                                forgotPasswordSteps.forgotPassword
                              )
                            }
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">Forgot Your Password?</h1>
                          <p className="leading-7 text-center mt-[22px]">
                            Enter the OTP sent on{" "}
                            <span className="font-semibold">{email}</span>
                          </p>

                          <div className="mt-[50px]">
                            <div className="relative">
                              <label>Enter OTP</label>
                              <div className="otp-input mt-2">
                                <OtpInput
                                  className="w-full p-4 rounded-xl"
                                  inputStyle="inputStyle"
                                  inputType="number"
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  placeholder="------"
                                  shouldAutoFocus={true}
                                  required
                                  renderInput={(props) => <input {...props} />}
                                />
                              </div>
                              <div className="absolute tp-message">
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </div>
                            </div>
                            <div class="flex mt-4 text-sm mb-[50px] justify-end items-center text-black">
                              <p className="text-right">
                                Didn't get OTP?{" "}
                                <a
                                  disabled={loading || resendOTPSeconds > 0}
                                  href="javascript:void(0);"
                                  onClick={() => onReSendOTP()}
                                  className="text-primaryLime-500 underline"
                                >
                                  Resend OTP{" "}
                                </a>
                                {resendOTPSeconds > 0 && (
                                  <span className="text-primaryLime-500">
                                    (00:{resendOTPSeconds}
                                    {constants.ResendOTPPreference})
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out mt-5"
                            aria-label="Sign in"
                            type="submit"
                            onClick={() => onVerifyForgotPassword()}
                            size="large"
                            sx={{ textTransform: "capitalize" }}
                            disabled={loading}
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Set Password"}
                          </Button>
                        </div>
                      )) ||
                      (forgotPasswordStep ===
                        forgotPasswordSteps.newPassword && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                            onClick={() =>
                              setForgotPasswordStep(
                                forgotPasswordSteps.forgotPasswordOTP
                              )
                            }
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">Forgot Your Password?</h1>
                          <p className="leading-7 text-center mt-[22px]">
                            Set Your New Password for{" "}
                            <span className="font-semibold">{email}</span>
                          </p>
                          <div className="mt-[50px]">
                            <form
                              name="loginForm"
                              noValidate
                              className="flex flex-col justify-center w-full"
                              onSubmit={handleSubmit(onForgotNewPassword)}
                            >
                              <div className="mb-8">
                                <label className="mb-2">Enter Password</label>
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      type={showPassword ? "text" : "password"}
                                      error={!!errors.password}
                                      helperText={errors?.password?.message}
                                      variant="outlined"
                                      placeholder="Enter Password"
                                      autoFocus={true}
                                      required
                                      fullWidth
                                      value={newPasswordField}
                                      onChange={(val) => {
                                        setNewPasswordField(val.target.value);
                                      }}
                                      inputProps={{ maxLength: 14 }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <a
                                              className="ps-eye-icon"
                                              aria-label="toggle password visibility"
                                              onClick={() => {
                                                setShowPassword(!showPassword);
                                              }}
                                              edge="end"
                                            >
                                              {showPassword ? (
                                                <i class="fi fi-rr-eye-crossed"></i>
                                              ) : (
                                                <i class="fi fi-rr-eye"></i>
                                              )}
                                            </a>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {newPasswordField.length > 0 ? (
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                    ]}
                                    minLength={8}
                                    value={newPasswordField}
                                    valueAgain={confirmPasswordField}
                                    className="ps-validation-msg mt-1"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="mb-8 relative">
                                <label className="mb-2">Confirm Password</label>
                                <Controller
                                  name="passwordConfirm"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24"
                                      type={
                                        showConfirmPassword
                                          ? "text"
                                          : "password"
                                      }
                                      error={!!errors.passwordConfirm}
                                      helperText={
                                        errors?.passwordConfirm?.message
                                      }
                                      variant="outlined"
                                      placeholder="Enter Password"
                                      required
                                      fullWidth
                                      value={confirmPasswordField}
                                      onChange={(val) => {
                                        setConfirmPasswordField(
                                          val.target.value
                                        );
                                      }}
                                      inputProps={{ maxLength: 14 }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <a
                                              className="ps-eye-icon"
                                              aria-label="toggle password visibility"
                                              onClick={() => {
                                                setShowConfirmPassword(
                                                  !showConfirmPassword
                                                );
                                              }}
                                              edge="end"
                                            >
                                              {showConfirmPassword ? (
                                                <i class="fi fi-rr-eye-crossed"></i>
                                              ) : (
                                                <i class="fi fi-rr-eye"></i>
                                              )}
                                            </a>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                <div className="absolute tp-message">
                                  <AppMessage
                                    status={appStatus}
                                    message={appMessage}
                                    vertical="top"
                                    horizontal="center"
                                    isOpen={appOpen}
                                    timeOut={100}
                                  ></AppMessage>
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                type="submit"
                                size="large"
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  newPasswordField.length < 1 ||
                                  newPasswordField !== confirmPasswordField
                                }
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Reset Password"}
                              </Button>
                            </form>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Dialog
                  open={showModal}
                  onClose={() => {
                    setShowModal(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                  >
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                    Password Changed Successfully
                  </DialogTitle>
                  <DialogContent className="dialog-content">
                    <DialogContentText id="alert-dialog-description">
                      <p className="text-black text-center">
                        Your password has been updated. You can now use your new
                        password to sign in.
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className="dialog-actions">
                    <button
                      className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out capitalize"
                      onClick={() => {
                        setShowModal(false);
                        navigate("/");
                      }}
                    >
                      OK
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
