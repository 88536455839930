class Constants {
  ResendOTPSeconds = 60;
  ResendOTPCount = 1000;
  ResendOTPPreference = "";
  signInSteps = () => {
    return {
      signIn: 0,
      signInNext: 1,
      signInWithOTP: 2,
      signInWithPassword: 3,
      signInWithRecovery: 4,
      signInWithRecoveryOTP: 5,
    };
  };

  forgotPasswordSteps = () => {
    return {
      forgotPassword: 0,
      forgotPasswordOTP: 1,
      newPassword: 2,
    };
  };

  signUpSteps = () => {
    return {
      signUp: 0,
      signUpWithOTP: 1,
      signUpSuccess: 2,
    };
  };


  signInSkipAttempts = () => {
    return {
      skipAttemptEnd: 2,
    };
  };

  messageStatusCodes = () => {
    return {
      success: 200,
      badRequest: 400,
      internalServerError: 500,
    };
  };

  settingTabSteps = () => {
    return {
      profile: 0,
      accountRecovery: 1,
    };
  };

  sidebarMenu = () => {
    return {
      dashboard: 0,
      settings: 1,
      signOut: 2,
    };
  };

  signInPreferenceTypeIDs = () => {
    return {
      signUp: 0,
      signInWihOtp: 1,
      signInWihPassword: 2,
      signInWihPasswordAndNoPreference: 3,
    };
  };

  resendOTP = () => {
    if (this.ResendOTPCount > 0) {
      const timerId = setInterval(() => {
        return (endCount) => endCount - 1;
      }, this.ResendOTPCount);
      return () => clearInterval(timerId);
    }
  };
}
const instance = new Constants();
export default instance;
