import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";

function Layout() {
  const [isActive, setIsActive] = useState(false);

  const handleOnAciveClick = () => {
    setIsActive(true);
  };

  const handleOnInAciveClick = () => {
    setIsActive(false);
  };
  return (
    <div className="flex h-screen layout">
      <div
        className={
          isActive
            ? "left-sidebar active max-w-[250px]"
            : "left-sidebar max-w-[250px]"
        }
      >
        <Sidebar handleOnInAciveClick={handleOnInAciveClick} />
      </div>
      <div className="w-full page-contain">
        <Header handleOnAciveClick={handleOnAciveClick} />
        <Outlet />
      </div>
      <span
        className={
          isActive
            ? "sidebar-overlay absolute active top-0 bottom-0 right-0 left-0"
            : "sidebar-overlay absolute top-0 bottom-0 right-0 left-0"
        }
      ></span>
    </div>
  );
}
export default Layout;
