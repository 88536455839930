import { useEffect, useState } from "react";
import authImage from "../../assets/images/tp-auth.png";
import successfullyMark from "../../assets/images/success-mark.png";
import logo from "../../assets/images/logo.png";
import * as yup from "yup";
import _ from "../../../../src/@lodash";
import AuthService from "../services/authService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import OtpInput from "react-otp-input";
import constants from "../../helpers/constants";
import { useNavigate, Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../main/app-message/message";
import InputAdornment from "@mui/material/InputAdornment";
import UserService from "../../main/services/userService";

var emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in)$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultEmailValues = {
  email: "",
};

var secondaryEmailSchema = yup.object().shape({
  secondaryEmail: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in)$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultSecondaryEmailValues = {
  secondaryEmail: "",
};

const schemaPwd = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - must be at least 4 chars."),
});

const defaultPwdValues = {
  password: "",
};

function SignIn() {
  var userDetail = AuthService.getUser();
  const signInSteps = constants?.signInSteps();
  const [signInStep, setSignInStep] = useState(signInSteps.signIn);

  const signUpSteps = constants?.signUpSteps();
  const [signUpStep, setSignUpStep] = useState(signUpSteps.signUp);

  const skipAttempts = constants?.signInSkipAttempts();
  const [skipAttempt, setSkipAttempt] = useState(
    userDetail?.users?.secondaryEmailAttempt || 0
  );

  const preferenceTypeIDs = constants?.signInPreferenceTypeIDs();
  const [signInPreferenceTypeID, setSignInPreferenceTypeID] = useState(
    preferenceTypeIDs.signUp
  );

  const [otp, setOtp] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [secondaryemailAddress, setSecondaryEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const [resendOTPSeconds, setResendOTPSeconds] = useState(
    constants.ResendOTPSeconds
  );

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  const navigate = useNavigate();

  var defaultValues =
    signInStep === signInSteps.signInWithPassword
      ? defaultPwdValues
      : signInStep === signInSteps.signInWithRecovery
      ? defaultSecondaryEmailValues
      : defaultEmailValues;
  var schema =
    signInStep === signInSteps.signInWithPassword
      ? schemaPwd
      : signInStep === signInSteps.signInWithRecovery
      ? secondaryEmailSchema
      : emailSchema;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (signInStep === signInSteps.signInWithPassword) {
      setValue("password", "", { shouldDirty: false, shouldValidate: false });
    } else setValue("email", "", { shouldDirty: false, shouldValidate: false });
  }, [setValue]);

  function onSubmit({ email }) {
    setLoading(true);
    setEmailAddress(email);
    AuthService.doesEmailAddressExists(email)
      .then((response) => {
        setLoading(false);
        showAppMessage(response);
        if (response?.statusCode === 200) {
          setIsSignIn(response.data?.isEmailExists);
          if (response.data?.isEmailExists) {
            setSignInPreferenceTypeID(response.data.signInpreferenceTypeID);
            if (
              response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPassword ||
              response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPasswordAndNoPreference
            ) {
              setValue("password", "", {
                shouldDirty: false,
                shouldValidate: false,
              });
              if (
                response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPasswordAndNoPreference
              )
                setSignInStep(signInSteps.signInNext);
              else setSignInStep(signInSteps.signInWithPassword);
            } else {
              signInWithOTP(email);
            }
          } else {
            showResendOTPSeconds();
            showHideOTPMessage();
            setOtp("");
            setTerms(false);
            setSignUpStep(signUpSteps.signUpWithOTP);
          }
        } else {
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
        // _errors.forEach((error) => {
        //     setError(error.type, {
        //         type: 'manual',
        //         message: error.message,
        //     });
        // });
      });
  }

  function signInWithOTP(email = "") {
    setLoading(true);
    AuthService.signInWithOTP(email ? email : emailAddress)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
            setOtp("");
            setTerms(false);
            setSignInStep(signInSteps.signInWithOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    AuthService.reSendOTP(emailAddress, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  async function onSignIn() {
    setLoading(true);
    if (signInStep === signInSteps.signInWithOTP) {
      await AuthService.signIn(emailAddress, true, otp)
        .then((response) => {
          showAppMessage(response);
          setLoading(false);
          if (response?.statusCode === 200) {
            if (response?.data) {
              userDetail = AuthService.getUser();
              setSkipAttempt(userDetail?.users?.secondaryEmailAttempt || 0);
              if (
                userDetail?.users?.secondaryEmailAttempt >
                skipAttempts.skipAttemptEnd
              ) {
                localStorage.setItem("authenticated", true);
                setTimeout(() => {
                  navigate("/home");
                }, 5);
              } else {
                setResendOTPSeconds(constants.ResendOTPSeconds);
                showResendOTPSeconds();
                setSignInStep(signInSteps.signInWithRecovery);
              }
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSignInWithPassword({ password }) {
    setLoading(true);
    if (signInStep === signInSteps.signInWithPassword) {
      AuthService.signIn(emailAddress, false, password)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              userDetail = AuthService.getUser();
              setSkipAttempt(userDetail?.users?.secondaryEmailAttempt || 0);
              if (
                userDetail?.users?.secondaryEmailAttempt >
                skipAttempts.skipAttemptEnd
              ) {
                localStorage.setItem("authenticated", true);
                navigate("/home");
              } else {
                setResendOTPSeconds(constants.ResendOTPSeconds);
                showResendOTPSeconds();
                setSignInStep(signInSteps.signInWithRecovery);
              }
            }
          }
          setLoading(false);
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function showHideOTPMessage(response) {
    setShowOtpMessage(true);
    setTimeout(() => {
      setShowOtpMessage(false);
    }, 3000);
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function showResendOTPSeconds() {
    var intervalId = setInterval(() => {
      setResendOTPSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return "00";
        }
        return prev < 11 ? `0${prev - 1}` : prev - 1;
      });
    }, constants.ResendOTPCount);
  }

  function onSignUpReSendOTP() {
    setLoading(true);
    AuthService.reSendOTP(emailAddress, false)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          setResendOTPSeconds(constants.ResendOTPSeconds);
          showResendOTPSeconds();
          showHideOTPMessage();
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onVerifySignUp() {
    setLoading(true);
    if (signUpStep === signUpSteps.signUpWithOTP) {
      AuthService.verifySignUp(emailAddress, otp)
        .then((response) => {
          setLoading(false);
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/home");
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSecondaryEmailSubmit({ secondaryEmail }) {
    setLoading(true);
    setSecondaryEmailAddress(secondaryEmail);
    UserService.addUpdateEmailAddress(secondaryEmail, 0, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setOtp("");
            setResendOTPSeconds(constants.ResendOTPSeconds);
          showResendOTPSeconds();
            setSignInStep(signInSteps.signInWithRecoveryOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onSkipEmail() {
    setLoading(true);
    UserService.updateSecondaryEmailCount(skipAttempt + 1)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setSkipAttempt(skipAttempt + 1);
            localStorage.setItem("authenticated", true);
            navigate("/home");
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onSecondaryOtpSubmit() {
    setLoading(true);
    UserService.verifyRecoveryEmail(secondaryemailAddress, otp)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            userDetail.recoveryEmailAddresses = response.data;
            AuthService.setUser(userDetail);
            localStorage.setItem("authenticated", true);
            navigate("/home");
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onSecondaryReSendOTP() {
    setLoading(true);
    UserService.reSendEmailOTP(secondaryemailAddress, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  // function onSignUp() {
  //   setLoading(true);
  //   if (signUpStep === signUpSteps.signUpSuccess) {
  //     AuthService.signUpUser(emailAddress, otp)
  //       .then((response) => {
  //         showAppMessage(response);
  //         if (response?.statusCode === 200) {
  //           if (response?.data) {
  //             navigate("/home");
  //           }
  //         }
  //         setLoading(false);
  //       })
  //       .catch((_errors) => {
  //         setLoading(false);
  //         showAppMessage(_errors);
  //       });
  //   }
  // }

  return (
    <div className="bg-black">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4"
                  src={logo}
                  alt="auth image"
                />
                <img className="mx-auto" src={authImage} alt="auth image" />
                <h1 className="auth-heading font-semibold mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="sm:w-[600px] w-full relative">
                  <img
                    className="mx-auto hidden xl:block"
                    src={logo}
                    alt="auth image"
                  />
                  <div className="bg-white w-full rounded-xl mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                    {(!isSignIn && signUpStep === signUpSteps.signUpWithOTP && (
                      <div>
                        <button
                          className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                          onClick={() => {
                            setIsSignIn(true);
                            setSignInStep(signInSteps.signIn);
                          }}
                        >
                          <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                        </button>
                        <h1 className="text-center">Sign Up and Get Started</h1>
                        <p className="leading-7 text-center mt-4">
                          Enter the OTP sent on{" "}
                          <span className="font-semibold">{emailAddress}</span>
                        </p>
                        <div class="mt-[50px]">
                          <div className="relative">
                            <label>Enter OTP</label>
                            <div className="otp-input mt-2">
                              <OtpInput
                                className="w-full p-4"
                                inputStyle="inputStyle"
                                value={otp}
                                inputType="number"
                                onChange={setOtp}
                                numInputs={6}
                                placeholder="------"
                                required
                                shouldAutoFocus={true}
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            <div className="absolute tp-message">
                              <AppMessage
                                status={appStatus}
                                message={appMessage}
                                vertical="top"
                                horizontal="center"
                                isOpen={appOpen}
                                timeOut={100}
                              ></AppMessage>
                            </div>
                          </div>
                          <div class="flex mt-4 justify-end items-center text-black">
                            <p className="text-right text-sm">
                              Didn't get OTP?{" "}
                              <a
                                disabled={loading || resendOTPSeconds > 0}
                                href="javascript:void(0);"
                                onClick={() => onSignUpReSendOTP()}
                                className="text-primaryLime-500"
                              >
                                Resend OTP
                              </a>{" "}
                              {resendOTPSeconds > 0 && (
                                <span className="text-primaryLime-500">
                                  (00:{resendOTPSeconds}
                                  {constants.ResendOTPPreference})
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center my-5">
                          <div className="flex items-center">
                            <Controller
                              name="terms"
                              control={control}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  onChange={(e) => setTerms(e.target.checked)}
                                  className="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                  required
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <label className="ms-2 text-sm">
                            I agree to the{" "}
                            <Link
                              className="underline text-primaryLime-500"
                              to="/terms"
                              target={"_blank"}
                            >
                              Terms & Conditions
                            </Link>
                          </label>
                        </div>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                          aria-label="Sign in"
                          type="submit"
                          onClick={() => onVerifySignUp()}
                          size="large"
                          sx={{ textTransform: "capitalize" }}
                          disabled={
                            !terms ||
                            (otp.length !== 6 && true) ||
                            false ||
                            loading
                          }
                        >
                          {(loading && (
                            <PulseLoader
                              color="#000000"
                              loading={loading}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )) ||
                            "Sign up"}
                        </Button>
                      </div>
                    )) ||
                      (signUpStep === signUpSteps.signUpSuccess && (
                        <div>
                          <span>
                            <img
                              className="mx-auto block mb-4 w-[50px]"
                              src={successfullyMark}
                              alt="successfully"
                            />
                          </span>
                          <h1 className="text-center">Welcome</h1>
                          <p className="mb-[50px] leading-7 text-center mt-4">
                            You're all set! Proceed to your dashboard
                          </p>
                          {/* <div className="relative tp-message">
                            <AppMessage
                              status={appStatus}
                              message={appMessage}
                              vertical="top"
                              horizontal="center"
                              isOpen={appOpen}
                              timeOut={100}
                            ></AppMessage>
                          </div> */}
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                            aria-label="Sign in"
                            type="submit"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                              //onSignUp();
                            }}
                            size="large"
                            disabled={loading}
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Explore Dashboard"}
                          </Button>
                        </div>
                      )) ||
                      (isSignIn &&
                        ((signInStep === signInSteps.signIn && (
                          <div>
                            <h1 className="text-center mb-6 sm:mb-8 md:mb-12 lg:mb-[50px]">
                              Welcome
                            </h1>
                            <form
                              name="loginForm"
                              noValidate
                              className="flex flex-col justify-center w-full"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="reative">
                                <label className="mb-2">Email Address</label>
                                <Controller
                                  name="email"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="w-full p-4"
                                      autoFocus={true}
                                      type="email"
                                      error={!!errors.email}
                                      helperText={errors?.email?.message}
                                      variant="outlined"
                                      placeholder="Enter Email address"
                                      required
                                      fullWidth
                                      inputProps={{ maxLength: 40 }}
                                    />
                                  )}
                                />
                                <div className="absolute tp-message">
                                  <AppMessage
                                    status={appStatus}
                                    message={appMessage}
                                    vertical="top"
                                    horizontal="center"
                                    isOpen={appOpen}
                                    timeOut={100}
                                  ></AppMessage>
                                </div>
                              </div>
                              <p className="my-[22px] leading-7">
                                Note: We will verify your account status. If
                                none exists, one will be created, followed by an
                                OTP sent to your email for verification.
                              </p>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  _.isEmpty(dirtyFields) || !isValid || loading
                                }
                                type="submit"
                                size="large"
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Next"}
                              </Button>
                            </form>
                          </div>
                        )) ||
                          (signInStep === signInSteps.signInNext && (
                            <div>
                              <button
                                className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                                onClick={() => {
                                  setSignInStep(signInSteps.signIn);
                                }}
                              >
                                <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                              </button>
                              <div>
                                <h1 className="text-center">
                                  Sign In to Your Account
                                </h1>
                                <p className="leading-7 text-center mt-3.5">
                                  Select your Sign in method for{" "}
                                  <span className="font-semibold">
                                    {emailAddress}
                                  </span>
                                </p>
                                <div
                                  className={
                                    signInPreferenceTypeID ===
                                    preferenceTypeIDs.signInWihPasswordAndNoPreference
                                      ? "flex gap-6 mt-[50px]"
                                      : "mt-[50px] w-full"
                                  }
                                >
                                  {signInPreferenceTypeID !==
                                    preferenceTypeIDs.signInWihPassword && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                      aria-label="Sign in with OTP"
                                      type="submit"
                                      size="large"
                                      disabled={loading}
                                      sx={{ textTransform: "capitalize" }}
                                      onClick={() => signInWithOTP()}
                                    >
                                      {(loading && (
                                        <PulseLoader
                                          color="#000000"
                                          loading={loading}
                                          size={10}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      )) ||
                                        "Sign in with OTP"}
                                    </Button>
                                  )}
                                  {(signInPreferenceTypeID ===
                                    preferenceTypeIDs.signInWihPassword ||
                                    signInPreferenceTypeID ===
                                      preferenceTypeIDs.signInWihPasswordAndNoPreference) && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                      aria-label="Sign in with Password"
                                      type="submit"
                                      size="large"
                                      sx={{ textTransform: "capitalize" }}
                                      onClick={() => {
                                        setValue("password", "", {
                                          shouldDirty: false,
                                          shouldValidate: false,
                                        });
                                        setSignInStep(
                                          signInSteps.signInWithPassword
                                        );
                                      }}
                                    >
                                      Sign in with Password
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )) ||
                          (signInStep === signInSteps.signInWithOTP && (
                            <div>
                              <button
                                className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                                onClick={() => {
                                  setSignInStep(
                                    signInPreferenceTypeID ===
                                      preferenceTypeIDs.signInWihPasswordAndNoPreference
                                      ? signInSteps.signInNext
                                      : signInSteps.signIn
                                  );
                                }}
                              >
                                <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                              </button>
                              <h1 className="text-center">Sign in with OTP</h1>
                              <p className="leading-7 text-center mt-3.5">
                                Enter the OTP sent on{" "}
                                <span className="font-semibold">
                                  {emailAddress}
                                </span>
                              </p>
                              <div className="mt-[50px]">
                                <div className="relative">
                                  <label>Enter OTP</label>
                                  <div className="otp-input mt-2">
                                    <OtpInput
                                      className="w-full p-4"
                                      inputStyle="inputStyle"
                                      value={otp}
                                      inputType="number"
                                      onChange={setOtp}
                                      numInputs={6}
                                      placeholder="------"
                                      shouldAutoFocus={true}
                                      required
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                    />
                                  </div>
                                  <div className="absolute tp-message">
                                    <AppMessage
                                      status={appStatus}
                                      message={appMessage}
                                      vertical="top"
                                      horizontal="center"
                                      isOpen={appOpen}
                                      timeOut={100}
                                    ></AppMessage>
                                  </div>
                                </div>
                                <div class="flex mt-4 mb-[22px] text-sm text-black justify-end items-center">
                                  <p className="text-right">
                                    Didn't get OTP?{" "}
                                    <a
                                      disabled={loading || resendOTPSeconds > 0}
                                      href="javascript:void(0);"
                                      onClick={() => onReSendOTP()}
                                      className="text-primaryLime-500"
                                    >
                                      Resend OTP{" "}
                                    </a>
                                    {resendOTPSeconds > 0 && (
                                      <span className="text-primaryLime-500">
                                        (00:{resendOTPSeconds}
                                        {constants.ResendOTPPreference})
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                type="submit"
                                sx={{ textTransform: "capitalize" }}
                                onClick={() => onSignIn()}
                                size="large"
                                disabled={
                                  (otp.length !== 6 && true) || false || loading
                                }
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Sign in"}
                              </Button>
                            </div>
                          )) ||
                          (signInStep === signInSteps.signInWithPassword && (
                            <div>
                              <button
                                className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                                onClick={() => {
                                  setSignInStep(
                                    signInPreferenceTypeID ===
                                      preferenceTypeIDs.signInWihPasswordAndNoPreference
                                      ? signInSteps.signInNext
                                      : signInSteps.signIn
                                  );
                                }}
                              >
                                <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                              </button>
                              <h1 className="text-center">
                                Sign in wih Password
                              </h1>
                              <p className="leading-7 text-center mt-3.5">
                                Enter your password for{" "}
                                <span className="font-semibold">
                                  {" "}
                                  {emailAddress}{" "}
                                </span>{" "}
                                to continue.
                              </p>
                              <div className="mt-[50px]">
                                <form
                                  name="loginForm"
                                  noValidate
                                  className="flex flex-col justify-center w-full"
                                  onSubmit={handleSubmit(onSignInWithPassword)}
                                >
                                  <div>
                                    <div className="relative">
                                      <label className="mb-2">Password</label>
                                      <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            className="mb-24"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            error={!!errors.password}
                                            helperText={
                                              errors?.password?.message
                                            }
                                            variant="outlined"
                                            placeholder="Enter Password"
                                            required
                                            fullWidth
                                            autoFocus={true}
                                            inputProps={{ maxLength: 14 }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <a
                                                    className="ps-eye-icon"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                      setShowPassword(
                                                        !showPassword
                                                      );
                                                    }}
                                                    edge="end"
                                                  >
                                                    {showPassword ? (
                                                      <i class="fi fi-rr-eye-crossed"></i>
                                                    ) : (
                                                      <i class="fi fi-rr-eye"></i>
                                                    )}
                                                  </a>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      <div className="absolute tp-message">
                                        <AppMessage
                                          status={appStatus}
                                          message={appMessage}
                                          vertical="top"
                                          horizontal="center"
                                          isOpen={appOpen}
                                          timeOut={100}
                                        ></AppMessage>
                                      </div>
                                    </div>

                                    <div className="mt-4 mb-[22px]">
                                      <p className="text-right ml-auto text-sm">
                                        <Link
                                          to="/forgot-password"
                                          state={{ email: emailAddress }}
                                          className="text-primaryLime-500"
                                        >
                                          Forgot Password?
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                    aria-label="Sign in"
                                    type="submit"
                                    size="large"
                                    sx={{ textTransform: "capitalize" }}
                                    disabled={
                                      _.isEmpty(dirtyFields) ||
                                      !isValid ||
                                      loading
                                    }
                                  >
                                    {(loading && (
                                      <PulseLoader
                                        color="#000000"
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                      />
                                    )) ||
                                      "Sign in"}
                                  </Button>
                                </form>
                              </div>
                            </div>
                          )) ||
                          (signInStep === signInSteps.signInWithRecovery && (
                            <div>
                              <h1 className="text-center mb-6 sm:mb-8 md:mb-12 lg:mb-[50px]">
                                Recovery Information
                              </h1>
                              <form
                                name="loginForm"
                                noValidate
                                className="flex flex-col justify-center w-full"
                                onSubmit={handleSubmit(onSecondaryEmailSubmit)}
                              >
                                <div className="reative">
                                  <label className="mb-2">
                                    Secondary Email Address
                                  </label>
                                  <Controller
                                    name="secondaryEmail"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="w-full p-4"
                                        autoFocus={true}
                                        type="email"
                                        error={!!errors.secondaryEmail}
                                        helperText={
                                          errors?.secondaryEmail?.message
                                        }
                                        variant="outlined"
                                        placeholder="Enter Email address"
                                        required
                                        fullWidth
                                        inputProps={{ maxLength: 40 }}
                                      />
                                    )}
                                  />
                                  <div className="absolute tp-message">
                                    <AppMessage
                                      status={appStatus}
                                      message={appMessage}
                                      vertical="top"
                                      horizontal="center"
                                      isOpen={appOpen}
                                      timeOut={100}
                                    ></AppMessage>
                                  </div>
                                </div>
                                <div className="mt-6 sm:mt-8 md:mt-12 lg:mt-[50px]">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                    aria-label="Sign in"
                                    sx={{ textTransform: "capitalize" }}
                                    disabled={
                                      _.isEmpty(dirtyFields) ||
                                      !isValid ||
                                      loading
                                    }
                                    type="submit"
                                    size="large"
                                  >
                                    {(loading && (
                                      <PulseLoader
                                        color="#000000"
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                      />
                                    )) ||
                                      "Next"}
                                  </Button>
                                </div>
                                {skipAttempt < skipAttempts.skipAttemptEnd && (
                                  <div className="flex justify-end mt-4">
                                    <Link
                                      onClick={() => {
                                        onSkipEmail();
                                      }}
                                      className="underline text-primaryLime-500"
                                    >
                                      Skip for Now
                                    </Link>
                                  </div>
                                )}
                              </form>
                            </div>
                          )) ||
                          (signInStep === signInSteps.signInWithRecoveryOTP && (
                            <div>
                              <button
                                className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                                onClick={() => {
                                  setIsSignIn(true);
                                  setSignInStep(signInSteps.signInWithRecovery);
                                }}
                              >
                                <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                              </button>
                              <h1 className="text-center">
                              Recovery Information
                              </h1>
                              <p className="leading-7 text-center mt-4">
                                Enter the OTP sent on{" "}
                                <span className="font-semibold">
                                  {secondaryemailAddress}
                                </span>
                              </p>
                              <div class="mt-[50px]">
                                <div className="relative">
                                  <label>Enter OTP</label>
                                  <div className="otp-input mt-2">
                                    <OtpInput
                                      className="w-full p-4"
                                      inputStyle="inputStyle"
                                      value={otp}
                                      inputType="number"
                                      onChange={setOtp}
                                      numInputs={6}
                                      placeholder="------"
                                      required
                                      shouldAutoFocus={true}
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                    />
                                  </div>
                                  <div className="absolute tp-message">
                                    <AppMessage
                                      status={appStatus}
                                      message={appMessage}
                                      vertical="top"
                                      horizontal="center"
                                      isOpen={appOpen}
                                      timeOut={100}
                                    ></AppMessage>
                                  </div>
                                </div>
                                <div class="flex mt-4 mb-[22px] text-sm text-black justify-end items-center">
                                  <p className="text-right">
                                    Didn't get OTP?{" "}
                                    <a
                                      disabled={loading || resendOTPSeconds > 0}
                                      href="javascript:void(0);"
                                      onClick={() => onSecondaryReSendOTP()}
                                      className="text-primaryLime-500"
                                    >
                                      Resend OTP
                                    </a>{" "}
                                    {resendOTPSeconds > 0 && (
                                      <span className="text-primaryLime-500">
                                        (00:{resendOTPSeconds}
                                        {constants.ResendOTPPreference})
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                type="submit"
                                onClick={() => onSecondaryOtpSubmit()}
                                size="large"
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  (otp.length !== 6 && true) || false || loading
                                }
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Sign in"}
                              </Button>
                            </div>
                          ))))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignIn;
