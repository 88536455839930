import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import constants from "../../helpers/constants";

function AppMessage(props) {
  var { status, message, vertical, horizontal, isOpen, timeOut } = props;
  const messageStatusCode = constants?.messageStatusCodes();

  const handleClose = () => {
    isOpen = false;
  };

  switch (status) {
    case messageStatusCode.success: {
      return isOpen ? <div className="success-msg">{message}</div> : "";
    }
    case messageStatusCode.badRequest: {
      return isOpen ? <div className="error-msg">{message}</div> : "";
    }
    case messageStatusCode.internalServerError: {
      return isOpen ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isOpen}
          onClose={() => handleClose()}
          key={vertical + horizontal}
          autoHideDuration={6000}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      );
    }
    default: {
      return null;
    }
  }
}

export default AppMessage;
