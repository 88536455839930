import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "./layout";
import AuthService from "../auth/services/authService";

const PrivateRoute = () => {
  if (!AuthService.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  // If authenticated, render the child routes
  return <Layout />;
};

export default PrivateRoute;
